import React, { useState } from 'react'
import { Heading, Paragraph } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import { JWK } from '../../api/vce-api/vce-api';


export interface PoaCredentialClaims {
  date_of_birth: string,
  given_name: string
  family_name: string,
  proxied_permissions: string // "create a bank account"
  //[key: string]: unknown
}

export interface PoaCredentialSdJwt extends PoaCredentialClaims {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK
}

export interface PoaCredentialVcJwt {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK

  vc?: {
    credentialSubject?: PoaCredentialClaims
  }
  credentialSubject?: PoaCredentialClaims
}

export function getPidClaims(credential: PoaCredentialSdJwt|PoaCredentialVcJwt): PoaCredentialClaims {
  return (credential as PoaCredentialVcJwt).credentialSubject || 
    (credential as PoaCredentialVcJwt).vc?.credentialSubject ||
    (credential as PoaCredentialSdJwt)
}

type Props = {
  credential: PoaCredentialSdJwt|PoaCredentialVcJwt 
}

const Poa = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  // TODO support other than sd-jwt:

  debugger;
  const claims = getPidClaims(props.credential);

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel
          control={<Switch checked={visualize}
          onChange={() => setVisualize(old => !old)} />}
          label={t("credentialView.visualize")}
          labelPlacement="start"
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(props.credential, null, 2)}</pre> :
      <div>
        <Heading variant='h4'>Given name</Heading>
        <Paragraph>
          {claims.given_name}
        </Paragraph>

        <Heading variant='h4'>Family name</Heading>
        <Paragraph>
          {claims.family_name}
        </Paragraph>

        <Heading variant='h4'>Date of birth</Heading>
        <Paragraph>
          {claims.date_of_birth}
        </Paragraph>

        <Heading variant='h4'>Proxied permissions</Heading>
        <Paragraph>
          {claims.proxied_permissions}
        </Paragraph>
      </div>
      }
    </Paper>
  )
}

export default Poa