
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import { RouterLink, WizardNavigation, WizardNavigationItem } from 'suomifi-ui-components'

import { AuthProvider } from '../../provider/AuthProvider';

// const PANKKI_API_URL = process.env.REACT_APP_PANKKI_API_URL || process.env.PANKKI_API_URL || "/api";
// const PANKKI_API_KEY = process.env.REACT_APP_PANKKI_API_KEY || process.env.PANKKI_API_KEY || "";


type Props = {}


const KycView = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [ pidCompleted, setPidCompleted ] = useState<boolean>(localStorage.getItem("pid") !== null);
  const [ lpidCompleted, setLpidCompleted ] = useState<boolean>(localStorage.getItem("lpid") !== null);
  const [ euccCompleted, setEuccCompleted ] = useState<boolean>(localStorage.getItem("eucc") !== null);
  const [ poaCompleted, setPoaCompleted ] = useState<boolean>(localStorage.getItem("poa") !== null);
  const [ summaryCompleted, setSummaryCompleted ] = useState<boolean>(localStorage.getItem("summary") !== null);

  //const [ pid, setPid ] = useState<boolean>(false);

  const [ page, setPage ] = useState<number>(1);

  const location = useLocation();

  useEffect(()=> {
    const pid = localStorage.getItem("pid");
    if (pid) {
      setPidCompleted(true);
    }
    // TODO somehow it is "undefined" string, not undefined
    // if (AuthProvider.isAuthenticated && AuthProvider.birthDate && AuthProvider.birthDate !== "undefined") {
    //   setPidCompleted(true);
    // }
    // if (AuthProvider.isAuthenticated && localStorage.getItem("pid") !== null) {
    //   setPidCompleted(true);
    // }
  },[]);


  const getPidStatus = (): "completed"|"current-completed"|"current"|"default"|"coming" => {
    if (location.pathname==="/kyc/pid") {
      return pidCompleted ? "current-completed" : "current";  
    }
    else {
      return pidCompleted ? "completed" : "default";
    }
  }

  const getLpidStatus = (): "completed"|"current-completed"|"current"|"default"|"coming" => {
    if (!pidCompleted) return "coming";
    if (location.pathname==="/kyc/lpid") {
      return lpidCompleted ? "current-completed" : "current";  
    }
    else {
      return lpidCompleted ? "completed" : "default";
    }
  }

  const getEuccStatus = (): "completed"|"current-completed"|"current"|"default"|"coming" => {
    if (!lpidCompleted || !pidCompleted) return "coming";
    if (location.pathname==="/kyc/eucc") {
      return euccCompleted ? "current-completed" : "current";  
    }
    else {
      return euccCompleted ? "completed" : "default";
    }
  }

  const getPoaStatus = (): "completed"|"current-completed"|"current"|"default"|"coming" => {
    if (!euccCompleted || !lpidCompleted || !pidCompleted) return "coming";
    if (location.pathname==="/kyc/poa") {
      return poaCompleted ? "current-completed" : "current";  
    }
    else {
      return poaCompleted ? "completed" : "default";
    }
  }

  const getSummaryStatus = (): "completed"|"current-completed"|"current"|"default"|"coming" => {
    if (!euccCompleted || !lpidCompleted || !pidCompleted) return "coming";
    if (window.location.pathname==="/kyc/summary") {
      return summaryCompleted ? "current-completed" : "current";  
    }
    else {
      return summaryCompleted ? "completed" : "default";
    }
  }

  return (
    <div style={{ display: "flex", flexDirection: "row" }} >
      { location.pathname !== "/kyc/final" &&
      <div style={{ minWidth: '350px', marginTop: "20px", marginRight: "30px"}}>
        <WizardNavigation heading="Open a bank account for a business" aria-label="Steps">
          <WizardNavigationItem status={getPidStatus()}>
            <RouterLink href="/kyc/pid" aria-label="1. Authentication. User is authenticated">
              1. Natural Person Authentication (PID)
            </RouterLink>
          </WizardNavigationItem>
          <WizardNavigationItem status={getLpidStatus()}>
            <RouterLink href="/kyc/lpid">
              2. Legal Person Authentication (LPID)
            </RouterLink>
          </WizardNavigationItem>
          <WizardNavigationItem status={getEuccStatus()}>
            <RouterLink aria-current="step" href="/kyc/eucc">
              3. EU Company Certificate (EUCC)
            </RouterLink>
          </WizardNavigationItem>
          <WizardNavigationItem status={getPoaStatus()}>
            <RouterLink aria-disabled role="link" tabIndex={0}  href="/kyc/poa">
              4. Additional documentations (PoA)
            </RouterLink>
          </WizardNavigationItem>
          <WizardNavigationItem status={getSummaryStatus()}>
            <RouterLink aria-disabled role="link" tabIndex={0} href="/kyc/summary">
              5. Summary and validation
            </RouterLink>
          </WizardNavigationItem>
        </WizardNavigation>
      </div>
      }
      <div style={{flexGrow: "1", maxWidth: "750px"}}>
      <Outlet context={{ 
        pageState: [page, setPage],
        pidState: [pidCompleted,setPidCompleted],
        //pidCrednetial 
        lpidState: [lpidCompleted,setLpidCompleted], 
        euccState: [euccCompleted,setEuccCompleted],
        poaState: [poaCompleted,setPoaCompleted],
        summaryState: [summaryCompleted,setSummaryCompleted]
      }}></Outlet>
      </div>
    </div>
  )
}

export default KycView