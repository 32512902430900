import React from 'react'
import { IconCheck, IconClose, IconHelp, Paragraph } from 'suomifi-ui-components'

type Props = {
    valid?: boolean,
    text: string
}

const KycLineCheck = (props: Props) => {
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "10px"}}> 
      
        { props.valid === true ? <IconCheck color='#00AA00'></IconCheck> :
        props.valid === false ?  
        <IconClose color='#EE0000'></IconClose> :
        <b style={{padding: "5px"}}>?</b>// <IconHelp></IconHelp>
       }
      <Paragraph>
        { props.text }
      </Paragraph>
    </div>
  )
}

export default KycLineCheck