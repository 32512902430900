function convertToArray<T>(input: string|Array<T>|T): Array<T> {
    debugger;
    if (input === undefined || input === null)
        return []; // return null?
    if (typeof input === "string") {
        input = input.trim();
        if (input.startsWith("[") && input.endsWith("]")) {
            return JSON.parse(input) as Array<T>;
        }
        else if (input.startsWith('"') && input.endsWith('"')) {
            return JSON.parse(input) as Array<T>;
        }
        else {
            return [ JSON.parse(input) as T ];
        }
    }
    if (!Array.isArray(input)) {
        return [ input as T ]
    }
    return input as Array<T>;
}

export default convertToArray;