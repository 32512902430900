import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { Heading, Button, Paragraph, Alert, InlineAlert, IconCheck, IconClose } from 'suomifi-ui-components'
import { LpidCredentialSdJwt } from '../credentials/Lpid'
import { EuccCredentialSdJwt, LegalRepresentative } from '../credentials/Eucc'
import { getPidClaims, PidCredentialSdJwt, PidCredentialVcJwt } from '../credentials/Pid'
import convertToArray from '../credentials/convertToArray'
import KycLineCheck from './KycLineCheck'

type Props = {}

const SummaryStep = (props: Props) => {

  const [valid, setValid] = useState<boolean>(false);
  debugger;
  //const [lpid, setValid] = useState<boolean>(false)
  const pid = getPidClaims(JSON.parse(localStorage.getItem("pid")!) as PidCredentialSdJwt|PidCredentialVcJwt);
  const lpid = JSON.parse(localStorage.getItem("lpid")!) as LpidCredentialSdJwt;
  const eucc = JSON.parse(localStorage.getItem("eucc")!) as EuccCredentialSdJwt;

  const legalPresentatives = eucc.legalRepresentative && convertToArray<LegalRepresentative>(eucc.legalRepresentative);
  debugger;
  const personRoleInEucc = legalPresentatives.find(x => new Date(x.birth_date||0).getTime() === new Date(pid.birth_date).getTime() && x.family_name === pid.family_name && x.given_name === pid.given_name)?.role
  
  const { summaryState } = useOutletContext<{ summaryState: [boolean, Dispatch<SetStateAction<boolean>>]}>();
  const [ summaryCompleted, setSummaryCompleted] = summaryState;

  useEffect(() => {
    const isValid = lpid.legal_person_id === eucc.legalIdentifier && !!personRoleInEucc;
    setValid(isValid);
    setSummaryCompleted(isValid);
  }, [lpid.legal_person_id, eucc.legalIdentifier, personRoleInEucc, setSummaryCompleted]);

  return (
    <div>
       <Heading variant='h3'>Summary</Heading>
       
        <Paragraph>
          Bank needs to verify that EUCC uses same company as LPID. <br/>
        </Paragraph>

        {/* <table>
          <tbody>
            <tr><td></td></tr>
          </tbody>
        </table> */}
        <Paragraph>Legal person id in LPID: { lpid.legal_person_id }</Paragraph>
        <Paragraph>Legal person id in EUCC: { eucc.legalIdentifier }</Paragraph>
        
        <KycLineCheck valid={true} text={"Credential signatures valid"}></KycLineCheck>
        <KycLineCheck valid={true} text={"Issuer key binding checked for processed credentials"}></KycLineCheck>
        <KycLineCheck valid={true} text={"Holder key binding checked for processed credentials"}></KycLineCheck>

        <KycLineCheck valid={undefined} text={"Issuer is trusted (not implemented yet)"}></KycLineCheck>

        <KycLineCheck valid={!!personRoleInEucc} text={"Person is authorized to open bank account"}></KycLineCheck>
        

        <KycLineCheck valid={eucc.legalIdentifier === lpid.legal_person_id} text={"LPID and EUCC credentials have same legal person"}></KycLineCheck>
        {/* { eucc.legalIdentifier !== lpid.legal_person_id && 
          <InlineAlert status="error">Company EUID does not match with LPID and EUCC credentials</InlineAlert>
        } */}
      
        {/* <Paragraph>
          Bank also needs to check that person who is applying to create bank account needs to have power to act for the company.
        </Paragraph>

        <Paragraph>Person given name in PID: { pid.given_name }</Paragraph>
        <Paragraph>{personRoleInEucc ? `Person role in EUCC: ${personRoleInEucc}` : "Person not found in EUCC!" }</Paragraph>

        { personRoleInEucc &&  
          <IconCheck color='#00AA00'></IconCheck>
        }
        { !personRoleInEucc && 
          <InlineAlert status="warning">Person is not part of EUCC credential, need power of attorney credential!</InlineAlert>
        } */}

        <br/>
        <Link to="/kyc/final">
          <Button disabled={!valid}>Accept</Button>
        </Link>

    </div>
  )
}

export default SummaryStep