import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Heading, Paragraph } from 'suomifi-ui-components'
import RequestCredential from '../../components/RequestCredential'
import { SimplifiedPresentationPayload } from '../../api/vce-api/vce-api'
import Eucc, { EuccCredentialSdJwt } from '../credentials/Eucc'
import { Link, useOutletContext } from 'react-router-dom'

type Props = {}

const KycEUCC = (props: Props) => {

  const { euccState } = useOutletContext<{
    pidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    lpidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    euccState: [boolean, Dispatch<SetStateAction<boolean>>]}>();
  const [euccCompleted, setEuccCompleted] = euccState;

  const storedEucc = localStorage.getItem("eucc");
  const [ eucc, setEucc ] = useState<EuccCredentialSdJwt|undefined>(storedEucc ? JSON.parse(storedEucc) : undefined); 

  const onCallback = (data: SimplifiedPresentationPayload) => {
    debugger
    localStorage.setItem("eucc", JSON.stringify(data.credentials[0], null, 2));
    setEucc(data.credentials[0] as EuccCredentialSdJwt);
    setEuccCompleted(true);
  }

  // TODO expiry_date commented out, some credentials might have incorrect expire_date
  const euccClaims = ["legalName", "legalFormType", "legalIdentifier", "registeredAddress", "postalAddress", "registrationDate", "shareCapital", "legalRepresentative", "legalEntityActivity", "contactPoint", "issuer_name", "issuer_id", "issuer_country", "issuance_date", /*"expiry_date",*/ "authentic_source_id", "authentic_source_name"];
  const euccClaimsCSV = euccClaims.join(",");

  return (
    <div>
      <Heading variant='h3'>EU Company Certificate</Heading>

      { !eucc ? 
      <> 
        <Paragraph>Here you can give EU Company Certificate to proof you have rights to act behalf of the given company.</Paragraph>
        <RequestCredential callback={onCallback} showWalletAddress={true} credentialType='EUCC' pathType='vc+sd-jwt' showOptions={true} claimsCSV={euccClaimsCSV}></RequestCredential>
      </> : 
      <Eucc credential={eucc}></Eucc>
      }

      <br />
      <Link to="/kyc/poa">
        <Button disabled={!eucc}>Next step</Button>
      </Link>
    </div>
    
  )
}

export default KycEUCC