import { SessionInfo, SuomiFiAuthorizationRole } from "../api/vce-api/vce-api";
import { useContext, createContext, useState, Dispatch, SetStateAction } from "react";


interface IAuthProvider {
  isAuthenticated: boolean
  
  username: null | string
  lastRefresh: number

  birthDate: string|null|undefined,

  companyName: string|null
  companyId: string|null
  authorizationRoles: SuomiFiAuthorizationRole[]

  setSessionInfo(session: SessionInfo): Promise<void>
  signin(username: string, birthDate?: string): Promise<void>
  signout(): Promise<void>
}

// const defaultAuthContext: IAuthProvider = {
//   isAuthenticated: false,
//   username: "",
//   lastRefresh: new Date().getTime(),
//   birthDate: null,
//   companyName: null,
//   companyId: null,
//   authorizationRoles:[],
//   setSessionInfo: async (session: SessionInfo) => {},
//   signin: async (username: string, birthDate?: string) => {},
//   signout: async () => {}
// }

// const AuthContext = createContext<IAuthProvider>(
//   defaultAuthContext
// );

// const AuthProvider = ({ children }) => {
//   return <AuthContext.Provider>{children}</AuthContext.Provider>;
// };

// export default AuthProvider;

// export const useAuth = () => {
//   return useContext(AuthContext);
// };

const refreshTokenValidSeconds = 3600;
/**
 * This represents some generic auth provider API, like Firebase.
 */
export const AuthProvider: IAuthProvider = {
  lastRefresh: parseInt(localStorage.getItem("lastRefresh")||"0", 10),
  isAuthenticated: localStorage.getItem("username") !== null && parseInt(localStorage.getItem("lastRefresh")||"0", 10) > (new Date().getTime() / 1000 - refreshTokenValidSeconds),
  username: localStorage.getItem("username"),
  birthDate: localStorage.getItem("birthDate"),
  companyName: localStorage.getItem("companyName"),
  companyId: localStorage.getItem("companyId"),
  authorizationRoles: localStorage.getItem("authorizationRoles") ? JSON.parse(localStorage.getItem("authorizationRoles")!) as SuomiFiAuthorizationRole[] : [],

  async signin(username: string, birthDate: string) {
    //await new Promise((r) => setTimeout(r, 500)); // fake delay
    AuthProvider.isAuthenticated = true;
    AuthProvider.username = username;
    
    AuthProvider.birthDate = birthDate;
    AuthProvider.lastRefresh = new Date().getTime() / 1000;

    localStorage.setItem("username", username);
    localStorage.setItem("birthDate", birthDate);
    localStorage.setItem("lastRefresh", (new Date().getTime()/1000).toString());
  },
  
  async signout() {
    //await new Promise((r) => setTimeout(r, 500)); // fake delay
    AuthProvider.isAuthenticated = false;
    AuthProvider.username = "";

    sessionStorage.clear();
    localStorage.clear();  
  },

  async setSessionInfo(session: SessionInfo) {
    
    const username = `${session.givenNames} ${session.lastname}`;
    this.signin(username);

    const companyName = session.authorizationRoles.length > 0 ? session.authorizationRoles[0].name : "";
    const companyId = session.authorizationRoles.length > 0 ? session.authorizationRoles[0].identifier : "";

    AuthProvider.companyId = companyId;
    AuthProvider.companyName = companyName;
    AuthProvider.authorizationRoles = session.authorizationRoles;
    localStorage.setItem("company", companyName);
    localStorage.setItem("companyId", companyId);
    localStorage.setItem("authorizationRoles", JSON.stringify(session.authorizationRoles)); // TODO?
  }
            
};
