import React from 'react'
import { Heading, Paragraph } from 'suomifi-ui-components'

type Props = {}

const KycFinal = (props: Props) => {
  return (
    <div>
      <Heading variant='h3'>
        Thank You for your application
      </Heading>
      <Paragraph>
        As a bank we need to know a customer, now we have received required identification documents from you. It will take some hours to process the application.<br/>
        If additional documents are already available, please submit them to us. <br/>
        In case additional documents are required, we will request them directly from your wallet.<br/> 
      </Paragraph>
      <Paragraph>
        We will contact you when the process is ready.
      </Paragraph>
    </div>
  )
}

export default KycFinal