import React, { Dispatch, SetStateAction, useState } from 'react'
import { Button, Heading, Paragraph } from 'suomifi-ui-components'
import RequestCredential from '../../components/RequestCredential'
import { SimplifiedPresentationPayload } from '../../api/vce-api/vce-api'
import Lpid, { LpidCredentialSdJwt } from '../credentials/Lpid'
import { Link, useOutletContext } from 'react-router-dom'

type Props = {}

const KycLPID = (props: Props) => {

  const { lpidState } = useOutletContext<{
    pidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    lpidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    euccState: [boolean, Dispatch<SetStateAction<boolean>>]}>();

  const [lpidCompleted, setLpidCompleted] = lpidState;

  const storedLpid = lpidCompleted ? localStorage.getItem("lpid") : null;
  const [ lpid, setLPID ] = useState<LpidCredentialSdJwt|undefined>(storedLpid !== null ? JSON.parse(storedLpid) : undefined); 
    

  const onCallback = (data: SimplifiedPresentationPayload) => {
    debugger
    localStorage.setItem("lpid", JSON.stringify(data.credentials[0], null, 2));
    setLPID(data.credentials[0] as LpidCredentialSdJwt);
    setLpidCompleted(true);
  }

  // TODO expiry_date removed some credentials might use expire_date ... put it back later
  const lpidClaims = ["legal_person_id", "legal_person_name", "issuer_name", "issuer_id", "issuer_country", "issuance_date", /*"expiry_date",*/ "authentic_source_id", "authentic_source_name", "credential_status"/*, "credential_schema"*/ ];
  const lpidClaimsCSV = lpidClaims.join(",");

  return (
    <div>
      <Heading variant='h3'>Legal Person Authentication</Heading>
      { !lpidCompleted &&
      <>
        <Paragraph>Here you can authenticate as a legal person by scanning the qr code or by giving the wallet address</Paragraph>
        <RequestCredential callback={onCallback} showWalletAddress={true} credentialType='LPID' pathType='vc+sd-jwt' showOptions={true}
          claimsCSV={lpidClaimsCSV}></RequestCredential>
      </>
      }
      { lpid && lpidCompleted &&  
        <Lpid credential={lpid}></Lpid>
      }

      <br />
      <Link to="/kyc/eucc">
        <Button disabled={!lpid}>Next step</Button>
      </Link>

    </div>
    
  )
}

export default KycLPID