import { useEffect, useState } from 'react';
import './App.css';

import { ThemeProvider, createTheme } from '@mui/material';
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import LoginView from './views/login/LoginView';
import EudtrcView, { startPresentationRequest } from './views/EudtrcView';
import Home from './views/Home';
import LogoutView from './views/login/LogoutView';
import CredentialView from './views/CredentialView';
import KycView from './views/kyc/KycView';
import { loginPostAction } from './views/login/LoginPostAction';
import SuomiFiAfterAuthView from './views/login/SuomiFiAfterAuthView';
import { useTranslation } from 'react-i18next';
import KycViewWaltId, { startKycWaltIdPresentationRequests } from './views/KycViewWaltId';
import KycPID from './views/kyc/KycPID';
import KycLPID from './views/kyc/KycLPID';
import SummaryStep from './views/kyc/SummaryStep';
import KycEUCC from './views/kyc/KycEucc';
import KycPOA from './views/kyc/KycPOA';
import KycFinal from './views/kyc/KycFinal';

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(0, 53, 122)'
    }
  },
  typography: {
    h1: { fontSize: '2em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h2: { fontSize: '1.75em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h3: { fontSize: '1.5em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    h4: { fontSize: '1.25em', fontWeight: 'bold', margin: '0.75em 0 0.75em 0' },
    fontFamily: '"Source Sans Pro", "Helvetica Neue", Arial, sans-serif',
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '5em 3em 1em 3em'
        }
      },
      defaultProps: {
        elevation: 0,
        square: true,
      }
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #ccc',
          padding: '1em 1em 1em 1em'
        }
      }
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    handle: "route.home",
    children: [
      {
        path: "suomifi",
        element: <SuomiFiAfterAuthView />
      },
      {
        path: "login",
        element: <LoginView />,
        action: loginPostAction
      },
      {
        path: "logout",
        element: <LogoutView />
      },
      {
        path: "eudtrc",
        element: <EudtrcView />,
        handle: "route.eudtrc",
        action: startPresentationRequest,
        children: [
          {
            path: "success",
            element: <CredentialView />
          }
        ]
      },
      {
        path: "kyc",
        element: <KycView />,
        handle: "route.kyc",
        children: [
          {
            path: "pid",
            element: <KycPID />
          },
          {
            path: "lpid",
            element: <KycLPID />
          },
          {
            path: "eucc",
            element: <KycEUCC />
          },
          {
            path: "poa",
            element: <KycPOA />
          },
          {
            path: "summary",
            element: <SummaryStep />
          },
          {
            path: "final",
            element: <KycFinal></KycFinal>
          }
        ]
      },
      {
        path: "kyc-old",
        element: <KycViewWaltId />,
        handle: "route.kyc",
        action: startKycWaltIdPresentationRequests,
        // children: [
        //   {
        //     path: "success",
        //     element: <KycView />
        //   }
        // ]
      }
    ],
  },
]);



function App() {

  const { t } = useTranslation();

  useEffect(() => {
    // only once request? aoo
    debugger;
  }, [])

  // Set window title
  useEffect(() => {
    document.title = t("appName");
  }, [t])
  
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}


export default App;