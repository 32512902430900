import React, { useEffect } from 'react'
import { Block, Heading,  Paragraph } from 'suomifi-ui-components';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { AuthProvider } from '../../provider/AuthProvider';

type Props = {}

const LogoutView = (props: Props) => {

  useEffect(() => {
    if (AuthProvider.isAuthenticated) {
      AuthProvider.signout();
      
    }
    
  },[]);
  
  return (
    <Block>
      <Heading variant="h1">{t('logoutView.title')}</Heading>  
      <Paragraph>
        <Link to='/'>{t('logoutView.redirect')}</Link>
      </Paragraph>
    </Block> 
  )
}

export default LogoutView