import { redirect } from 'react-router-dom';
import { IActionData } from './LoginView';
import { AuthProvider } from '../../provider/AuthProvider';


export const loginPostAction = async ({ request }: any): Promise<Response | IActionData> => {
  
  const data = Object.fromEntries(await request.formData());
  const username = data.email as string;

  try {

    //localStorage.setItem("birth_date", "2000-01-01");
    //localStorage.setItem("family_name",  "Meikäläinen");
    //localStorage.setItem("given_names", "Matti");

    localStorage.setItem("user", username);
    localStorage.setItem("refreshTime", (new Date().getTime()/1000).toString());

    AuthProvider.signin(username);

    const searchParams = new URLSearchParams(document.location.search)
    const redirectpath = searchParams.get("redirect");

    return redirect(redirectpath?.startsWith("/") ? redirectpath : "/");
  }
  catch (err: unknown) {
    if (err instanceof Error) {
      return { error: err.message, datetime: new Date() } as IActionData
    }
    return { error: typeof(err) === "string" ? err : "Unknown error" } as IActionData
  }
}
