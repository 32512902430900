import { Trans, useTranslation } from 'react-i18next'
import { Link, Outlet, useLocation } from 'react-router-dom'
import { Block, Button, Heading, Paragraph, IconArrowRight } from 'suomifi-ui-components'

import Footer from '../components/Footer'
import Header from '../components/Header'
import { AuthProvider } from '../provider/AuthProvider'

function Home() {
  const location = useLocation();
  const { t, i18n } = useTranslation();


  const isLoggedIn = AuthProvider.isAuthenticated;

  return (
    <>
      <Header></Header>
      <Block variant='main'>
        <Block variant='section'>
          {location.pathname === "/" &&
            <>
              <Heading variant="h1">{t('welcome')}</Heading>
              <Paragraph>
                <Trans i18nKey="homeView.pageDescription" components={{
                  link1: <Link to={ t('ydUrl')}>yrityksen digitalous</Link>,
                  link2: <Link to={ t('ewcUrl')}>EWC LSP</Link>
                }}></Trans>
              </Paragraph>
              { isLoggedIn ?
                <>
                  <br/>
                  <Link to="/kyc/pid">
                    <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.kycButton") + "(EWC experiment / EUDI Wallet)"}</Button>
                  </Link>
                  <br/><br/>
                  <Link to="/kyc-old">
                    <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.kycButton") + " (YD experiment / Walt.id)"}</Button>
                  </Link>
                  <br/>
                  <br/>
                  <Link to="/eudtrc">
                    <Button iconRight={<IconArrowRight></IconArrowRight>}>{t("homeView.etrcButton") + " (YD experiment / Walt.id)"}</Button>
                  </Link>
                </>
                :
              <Paragraph>{t('homeView.pageInfo')}</Paragraph> 
              }
            </>
          }
          <Outlet></Outlet>
        </Block>
      </Block>
      <Footer />
    </>
  )
}

export default Home