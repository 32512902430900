import React, { useState } from 'react'
import { Paragraph } from 'suomifi-ui-components';
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material';
import { t } from 'i18next';
import { JWK } from '../../api/vce-api/vce-api';


export interface PidCredentialClaims {
  family_name: string
  given_name: string
  birth_date: string
}

export interface PidCredentialSdJwt extends PidCredentialClaims {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK
}

export interface PidCredentialVcJwt {
  iss: string
  iat: number
  exp: number
  cvt: string
  cnf?: JWK

  vc?: {
    credentialSubject?: PidCredentialClaims
  }
  credentialSubject?: PidCredentialClaims
}

export function getPidClaims(credential: PidCredentialSdJwt|PidCredentialVcJwt): PidCredentialClaims {
  return (credential as PidCredentialVcJwt).credentialSubject || 
    (credential as PidCredentialVcJwt).vc?.credentialSubject ||
    (credential as PidCredentialSdJwt)
}

type Props = {
  credential: PidCredentialSdJwt|PidCredentialVcJwt // | LpidCredentialJwt
}

const Pid = (props: Props) => {
  const [visualize, setVisualize] = useState<boolean>(true);
  // TODO support other than sd-jwt:
  const vc = /*props.credential.vc?.credentialSubject ||*/ props.credential;

  debugger;
  const claims = getPidClaims(vc);

  return (
    <Paper style={{paddingTop: "30px", paddingBottom: "50px" }}>

      <FormGroup>
        <FormControlLabel
          control={<Switch checked={visualize}
          onChange={() => setVisualize(old => !old)} />}
          label={t("credentialView.visualize")}
          labelPlacement="start"
        />
      </FormGroup>

      { !visualize ? <pre>{JSON.stringify(vc, null, 2)}</pre> :
      <div>
         <Paragraph>
          <b>Given names:</b><br/>
          { claims.given_name }
          <br/><br/>
          <b>Family name:</b><br/>
          { claims.family_name }
          <br/><br/>
          <b>Birth date:</b><br/>
          { claims.birth_date }
          <br/><br/>
        </Paragraph>
      </div>
      }
    </Paper>
  )
}

export default Pid