import React, { Dispatch, SetStateAction, useState } from 'react'
import { Link, useOutletContext } from 'react-router-dom';
import { Button, Heading, Paragraph } from 'suomifi-ui-components'
import RequestCredential from '../../components/RequestCredential';
import { SimplifiedPresentationPayload } from '../../api/vce-api/vce-api';
import Pid, { PidCredentialSdJwt, PidCredentialVcJwt } from '../credentials/Pid';

type Props = {}

const KycPID = (props: Props) => {

  const { pidState } = useOutletContext<{
    pidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    lpidState: [boolean, Dispatch<SetStateAction<boolean>>], 
    euccState: [boolean, Dispatch<SetStateAction<boolean>>]}>();
  
  //const [pageCompleted, setPage] = pageState;
  const [pidCompleted, setPidCompleted ] = pidState;
  const storedPid = localStorage.getItem("pid");
  debugger;
  const [ pid, setPID ] = useState<PidCredentialSdJwt|PidCredentialVcJwt|undefined>(storedPid !== null ? JSON.parse(storedPid) : undefined); 
  
  const pidClaimsCSV = ["given_name","family_name","birth_date"].join(",");

  const onCallback = (data: SimplifiedPresentationPayload) => {
    debugger
    localStorage.setItem("pid", JSON.stringify(data.credentials[0], null, 2));
    setPID(data.credentials[0] as PidCredentialSdJwt|PidCredentialVcJwt);
    setPidCompleted(true);
  }

  return (
    <div>
        <Heading variant='h3'>Natural Person Authentication</Heading>
        { !pidCompleted && 
        <>
          <Paragraph>Here you can authenticate as a natural person</Paragraph>

          <RequestCredential callback={onCallback} showWalletAddress={false} credentialType='PID' showOptions={true} claimsCSV={pidClaimsCSV}></RequestCredential>
        </>
      }
      { pidCompleted && pid && 
        <>
          <Paragraph>You have authenticated with following details:</Paragraph>
          <Pid credential={pid}></Pid>
        </>
      }
       
      <Link to="/kyc/lpid">
        <Button disabled={!pid}>Next step</Button>
      </Link>


    </div>
  )
}

export default KycPID